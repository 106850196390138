const moment = require('moment')

const url = 'https://issuudownload.com/'
const shortTitle = 'IssuuDownload'
const description =
  "IssuuDownload.com is a free online tool for converting any document from Issuu to an optimized PDF. It's free and easy to use."

const now = moment().utc()
const currentYear = now.year()
const updatedAt = now.format()

const exampleUrls = ['https://issuu.com/diymagazine/docs/diy_march_2018']

let apiRootUrl = 'https://backend.img2pdf.net'
if (process.env.NODE_ENV === 'development') {
  apiRootUrl = 'http://localhost:4444'
}

const config = {
  enabled: true,
  documentRoot: '/:author/docs/:document',
  analyticsDocumentRoot: '/:author/docs/:document',
  apiRootUrl,
  adsense: {
    enabled: true,
    adBlockingMessage: {
      enabled: true,
    },
  },
  cookieConsent: {
    enabled: false,
  },
  fundingchoices: {
    enabled: false,
  },
  sirdata: {
    enabled: true,
    script: `
      <script type="text/javascript" src="https://cache.consentframework.com/js/pa/31053/c/sRiMO/stub"></script>
      <script type="text/javascript" src="https://choices.consentframework.com/js/pa/31053/c/sRiMO/cmp" async></script>`,
  },
  getPathname: (url) => {
    const parsedUrl = new URL(url)
    const query = parsedUrl.search || ''
    return `${parsedUrl.pathname}${query}`
  },
  getDocumentUrlFromParams: (params) => {
    const { author, document } = params
    return `https://issuu.com/${author}/docs/${document}`
  },
  service: {
    name: 'Issuu',
    url: 'https://www.issuu.com',
  },
  url,
  updatedAt,
  currentYear,
  shortTitle,
  exampleUrls,
  author: 'Ludovic LEFEVRE',
  analytics: {
    msvalidate: 'FF7F7420E56C44034A6FF055E17FCB40',
    viewId: '149609629',
    propertyId: '321867145',
    yandex: '34be50795e6c891f',
    gtm: 'GTM-M88PRC6',
  },
  contactEmail: 'contact@issuudownload.com',
  meta: {
    description,
    keywords: 'issuu, pdf, download,free,issuu to pdf,issuu download,issuu pdf download,pdf download,issuu downloader',
    author: 'Ludovic LEFEVRE',
    title: 'Issuu To PDF Download Tool',
    applicationName: 'IssuuDownload.com',
  },
  openGraph: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  twitter: {
    title: shortTitle,
    description,
    image: `${url}logo-228x228.png`,
  },
  manifest: {
    title: 'Issuu Download',
    shortTitle: 'Issuu Download',
  },
  share: {
    facebook: 'http://www.facebook.com/sharer.php?u=https%3A%2F%2Fissuudownload.com',
    twitter: 'https://twitter.com/share?url=https%3A%2F%2Fissuudownload.com&text=IssuuDownload.com',
    linkedin:
      'https://www.linkedin.com/shareArticle?mini=true&url=https%3A//issuudownload.com&title=Issuu%20Document%20To%20PDF&summary=&source=',
  },
  validator: {
    documentUrl: 'issuu',
  },
  screenshots: ['/img/issuu/get-issuu-document-url-01.jpg', '/img/issuu/get-issuu-document-url-02.jpg'],
}

export default config
